<script>
	import {
		Carousel,
		Slide
	} from "vue-carousel";
	import {
		UserIcon,
		MailIcon,
		KeyIcon,
		ArrowUpIcon,
		HelpCircleIcon,
	} from "vue-feather-icons";

	import storage from '@/storage';
	import Features from "@/components/features";
	import Testimonial from "@/components/testimonial";

  import Navbar from "@/views/landing/navbar";
  import Footer from "@/views/landing/footerBar";


	/**
	 * Index-1
	 */
	export default {
    metaInfo: {
      title: 'IncCrypto: The Best Cloud Mining Platform and Service Provider for Cloud Mining Technology Services',
      link: [
        { rel: 'canonical', href: 'https://inccrypto.com/questions' }
      ]
    },
		data() {
			return {
				data: [{
					title: "1. What is cloud mining?",
					content: "Cloud mining is the process of cryptocurrency mining utilizing a remote datacenter with shared processing power.This type of cloud mining enables users to mine bitcoins or alternative cryptocurrencies without managing the hardware. The mining rigs are housed and maintained in a facility owned by mining company and the customer simply needs to register and purchase mining contracts or shares."
				}, {
					title: "2. How do i get started with IncCrypto?",
					content: "Sign up for a free membership account with your email address, then top up your account, choose from many cloud mining contracts, earn outputs on a daily basis!"
				}, {
					title: "3. What are Cloud Mining Advantages?",
					content: "No excess heat to deal with, no constantly noise of fan, low electricity costs, no need space, no requires hardware/software/technical experience, no Pre-ordered mining hardware that may not be delivered on time, instant connection, 24/7 uptime."
				}, {
          title: "4. Can I buy more than one cloud mining contract?",
          content: "Yes, you can buy multiple contracts, or even contracts of different algorithms."
        }, {
            title: "5. When are rewards paid out?",
            content: "The rewards are paid out daily and withdrawal anytime."
        }, {
            title: "6. Do I need to pay electricity and maintenance costs?",
            // content: "Yes, you need to pay 10% of your balance as taxes and the withdrawal function limit will be activated after payment."
            content: "No, no extra fees. The electricity and maintenance costs are included in the contract cost."
        }, {
					title: "7. Is there a minimum or maximum purchase amount?",
					content: "Minimum purchase amounts differs for every contract, you may find it on our pricing page."
				}, {
					title: "8. Is there any minimum withdrawal amount?",
					content: "Our minimum withdrawal is $200."
				}, {
          title: "9. Is crypto currency mining profitable?",
          content: "Yes, we strongly believe that mining is profitable and Mining is the best way to get cryptocurrencies at cost price. Mining is the best long-term investment."
        }, {
          title: "10. What payment methods do you support?",
          content: "You can pay for cloud mining contracts using cryptocurrencies such as BTC, ETH, LTC, USDT."
        }, {
          title: "11. What is the referral program?",
          content: "Every IncCrypto customers has a unique referral link, which can be shared with anyone, copy your referral link and share this link to get bonuses. Any new user who registers using your referral link becomes a lifetime referral. You can start earning money even if you do not invest. As a referrer, you are eligible to receive a 3% referral commission bonus for every purchase made by any of your referrals. For example, if someone purchases a $1000 order using your referral code, you'll get $30 for free."
        }],
				login: false
			}
		},
		components: {
			Carousel,
			Slide,
			UserIcon,
			MailIcon,
			KeyIcon,
			ArrowUpIcon,
			Footer,
			HelpCircleIcon,
			Features,
			Testimonial,
			Navbar,
		},
		created() {
			//if login
			let userId = storage.localGet("userId")
			if (userId) {
				this.login = true;
			} else {

			}
		}
	};
</script>

<template>
	<div>
		<Navbar v-if="!login" />
		<Navbar v-else />


		<!-- 顶部 -->
		<section class="bg-half-170 d-table w-100 pb-0" style="padding-top: 100px;">
			<div class="row justify-content-center">
				<div class="col-12 text-center">
					<div class="section-title mb-4 pb-2">
						<h4 class="title mb-4">Frequently Asked Questions</h4>
						<p class="text-muted mx-auto mb-0">
							If you could not find an answer to your questions,please contant our customer support
						</p>
					</div>
				</div>
			</div>
		</section>
		<section class="section pt-0" style="margin: 0 10px;">
			<div class="container" style="box-shadow: 0 0 2.5px #ddd;border-radius: 10px;">
				<el-collapse v-model="activeName" accordion>
					<el-collapse-item v-for="(item,index) in data" :key="index" :name="index">
						<template #title>
							<div style="font-weight: bold;font-size: 16px;line-height: 24px;">{{ item.title }}</div>
						</template>
						<div style="font-size: 16px;color:Grey;" v-html="item.content"></div>
					</el-collapse-item>
				</el-collapse>
			</div>
		</section>
		<Footer />
		<!-- Footer End -->

		<!-- Back to top -->
		<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
			v-scroll-to="'#topnav'">
			<arrow-up-icon class="icons"></arrow-up-icon>
		</a>
		<!-- Back to top -->
	</div>
</template>

<style scoped>
.el-collapse-item__header{
  line-height: 24px;
}
</style>
